<template>
  <v-dialog
    v-model="dialogAddEditDespesas"
    @click:outside="$emit('update:dialogAddEditDespesas', false)"
    @keydown.esc="$emit('update:dialogAddEditDespesas', false)"
    width="1000px"
    scrollable
  >
    <v-card>
      <v-card-title class="tabs primary--text font-weight-bold">
        {{
          edit
            ? $tc("global.editar") + " " + $tc("global.planoDeGastos")
            : $tc("global.add") + " " + $tc("global.planoDeGastos")
        }}
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <div class="pr-4">{{ $tc("global.possuifatura?") }}</div>
          <v-switch
            :disabled="edit"
            class="pr-6"
            v-model="possui_fatura"
          ></v-switch>
        </div>
        <v-btn icon @click="$emit('update:dialogAddEditDespesas', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-tabs class="" v-model="tab">
        <v-tab class="caption font-weight-bold">
          {{ $tc("global.informacoes") }}
        </v-tab>
        <v-tab v-show="possui_fatura" class="caption font-weight-bold">
          {{ $tc("global.fatura") }}
        </v-tab>

        <v-tab v-show="edit" class="caption font-weight-bold">
          {{ $tc("global.midia", 2) }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat style="overflow: auto">
            <v-card-text class="py-6" style="height: auto">
              <v-form
                @submit.prevent
                ref="form"
                v-model="validForm"
                lazy-validation
              >
                <v-row dense>
                  <v-col class="py-3" cols="12" md="5">
                    <v-text-field
                      v-model="despesa.ncontrole"
                      label="Documento"
                      outlined
                      hide-details
                      dense
                      required
                      :rules="formRules"
                    ></v-text-field>
                  </v-col>

                  <!-- Fornecedores -->
                  <v-col class="py-3" cols="12" md="3">
                    <v-combobox
                      v-model="selectedFornecedor"
                      :label="$tc('global.pagarpara')"
                      :items="fornecedores"
                      item-text="nome"
                      item-value="id"
                      dense
                      outlined
                      clearable
                      hide-details
                      class="mb-2"
                      required
                      :rules="formRules"
                    ></v-combobox>
                  </v-col>

                  <v-col class="py-3" cols="12" md="3">
                    <v-combobox
                      v-model="selectedTipoDespesa"
                      class="ma-0"
                      :items="despesas_tipos"
                      :label="$tc('global.tipo') + ' ' + $tc('global.despesa')"
                      clearable
                      item-text="descricao"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                    ></v-combobox>
                  </v-col>

                  <v-btn
                    plain
                    outlined
                    class="my-3 custom_tamanho_btn"
                    @click="addTipoDespesa"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>

                  <DialogComprasTiposDespesas
                    v-if="dialogComprasTiposDespesas"
                    :dialogComprasTiposDespesas.sync="
                      dialogComprasTiposDespesas
                    "
                    :edit="edit_tipo_despesa"
                    @getComprasTiposDespesas="getUltimaDespesa"
                  />

                  <!-- FORMA PAGAMENTO --->
                  <v-col
                    class="py-3"
                    cols="12"
                    :md="despesa.condpago === 2 ? '2' : '6'"
                  >
                    <v-select
                      v-model="selectedCondPago"
                      :items="condicoes_pagamento"
                      clearable
                      outlined
                      dense
                      item-text="text"
                      item-value="value"
                      :label="$tc('global.formapagamento')"
                      hide-details
                      class="mb-2"
                      required
                      :rules="formRules"
                    ></v-select>
                  </v-col>

                  <!-- v-if="despesa.condpago === 2" -->
                  <v-col
                    v-if="despesa.condpago === 2"
                    class="py-3"
                    cols="12"
                    md="4"
                  >
                    <DataField
                      hide_details
                      :data_sync.sync="despesa.data_vencimento"
                      :label="$tc('global.data-vencimento')"
                      obrigatorio
                      hide-details
                    />
                  </v-col>

                  <!-- OBSERVACAO -->
                  <v-col class="py-3" cols="12" md="6">
                    <!-- :md="despesa.condpago === 2 ? '4' : '8'" -->
                    <v-text-field
                      v-model="despesa.obs"
                      :label="$tc('global.observacao')"
                      outlined
                      hide-details
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col class="py-3 d-flex align-end" cols="12" md="6">
                    <!-- Moeda -->
                    <v-combobox
                      v-model="selectedMoeda"
                      :label="$tc('global.moeda')"
                      :items="moedas"
                      item-text="sigla"
                      item-value="id"
                      dense
                      outlined
                      hide-details
                      required
                      :rules="formRules"
                    ></v-combobox>

                    <v-text-field
                      v-if="utilizarCambio"
                      v-model="cambioDespesa"
                      label="Câmbio"
                      outlined
                      class="pl-4"
                      required
                      :rules="formRules"
                      hide-details
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col v-show="utilizarCambio" class="py-3" cols="12" md="6">
                    <v-text-field
                      v-model="valorDespesaOrigem"
                      :label="$tc('global.valor')"
                      outlined
                      hide-details
                      dense
                      required
                      ref="valorDespesaOrigem"
                      @blur="setValorCambio"
                      :disabled="cambioDespesa ? false : true"
                      :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
                      v-currency="vCurrencyOptions2"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    class="py-3"
                    cols="12"
                    :md="utilizarCambio ? '12' : '6'"
                  >
                    <v-text-field
                      v-model="valorDespesa"
                      :label="utilizarCambio ? 'Valor Câmbio' : 'Valor'"
                      outlined
                      hide-details
                      dense
                      required
                      ref="valorDespesa"
                      :prefix="moeda_despesa ? moeda_despesa.sigla : ''"
                      :disabled="utilizarCambio ? true : false"
                      v-currency="vCurrencyOptions"
                      :rules="formRules"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="!edit" cols="12">
                    <v-card outlined class="mb-5">
                      <v-file-input
                        suffix="Max 100MB"
                        messages="Formatos permetidos : .jpg, .png, .txt, .xls, .xlsx, .pdf, .doc, .docx, .odt."
                        v-model="files"
                        placeholder="Anexos"
                        accept=".jpg,.png,.txt,.xls,.xlsx,.pdf,.doc,.docx,.odt"
                        multiple
                        class="mb-2 mx-2"
                        prepend-icon="mdi-paperclip"
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip
                            small
                            label
                            close
                            close-icon="mdi-delete"
                            color="primary"
                            @click:close="remove(text)"
                          >
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item class="px-6 py-4">
          <FaturaGastos
            v-if="possui_fatura"
            :fatura.sync="fatura"
            :fatura_edit="fatura_edit"
            :edit="edit"
            compras_despesas
            @set-valor-fatura="verificaValorFatura"
          />
        </v-tab-item>
        <v-tab-item v-if="edit">
          <InternFileManager
            v-if="tab === 2"
            origem="compras_despesas"
            :origem_id="despesa.id"
          />
        </v-tab-item>
      </v-tabs-items>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="tab == 0 && possui_fatura"
          small
          color="button_1"
          class="white--text"
          @click="goToFatura"
        >
          {{ $tc("global.fatura") }}
          <v-icon left>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn
          v-if="!possui_fatura || tab == 1"
          small
          color="button_2"
          class="white--text"
          @click.stop="!edit ? createDespesa() : updateDespesa()"
        >
          <v-icon left>mdi-check</v-icon>
          confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchFornecedoresList } from "@/api/fornecedores/fornecedores.js";
import { inputRequired } from "@/helpers/utils.js";

import { mapState } from "vuex";
import { postDespesa, putDespesa } from "@/api/compras/compras_despesas.js";
import { fetchAllComprasTiposDespesas } from "@/api/compras/compras_tipos_despesas.js";
import { fetchFaturaGasto } from "@/api/faturas-gastos/faturas-gastos.js";

export default {
  name: "DialogAddEditDespesas",

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
    InternFileManager: () =>
      import("@/components/midias/InternFileManager.vue"),
    FaturaGastos: () => import("@/components/global/FaturaGastos.vue"),
    DialogComprasTiposDespesas: () =>
      import(
        "@/views/dashboard/compras/compras_plano_contas/components/DialogComprasTiposDespesas.vue"
      ),
  },

  props: {
    dialogAddEditDespesas: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    compra_id: {
      type: Number,
    },
    moeda_despesa: {
      type: Object,
    },
  },

  data() {
    return {
      tab: 0,
      loading: false,
      despesa: {},
      selectedFornecedor: null,
      fornecedores: [],
      formRules: [inputRequired],
      validForm: true,
      selectedMoeda: null,
      valorDespesaOrigem: null,
      valorDespesa: null,
      cambioDespesa: null,
      despesas_tipos: [],
      selectedTipoDespesa: null,
      despesa_original: {},
      dialogComprasTiposDespesas: false,
      edit_tipo_despesa: false,
      condicoes_pagamento: [
        {
          text: this.$tc("global.aVista"),
          value: 1,
        },
        {
          text: this.$tc("global.aPrazo"),
          value: 2,
        },
      ],
      selectedCondPago: null,
      block: null,
      files: [],
      possui_fatura: null,
      fatura: {},
      fatura_liberada: false,
      fatura_edit: {},
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    utilizarCambio() {
      let result = false;
      if (this.selectedMoeda) {
        if (this.selectedMoeda.id_moeda !== this.moeda_despesa.id_moeda) {
          result = true;
        }
      }
      return result;
    },

    vCurrencyOptions() {
      return {
        precision: this.moeda_despesa
          ? this.moeda_despesa.sigla === "G$"
            ? 0
            : undefined
          : "",
        distractionFree: false,
      };
    },
    vCurrencyOptions2() {
      return {
        precision: this.selectedMoeda
          ? this.selectedMoeda.sigla === "G$"
            ? 0
            : undefined
          : "",
        distractionFree: false,
      };
    },
  },

  watch: {
    selectedTipoDespesa() {
      if (this.selectedTipoDespesa) {
        this.despesa.compras_tipos_despesas_id = this.selectedTipoDespesa.id;
      }
    },
    selectedFornecedor() {
      if (this.selectedFornecedor) {
        this.despesa.fornecedor_id = this.selectedFornecedor.id;
        this.despesa.ncontrole = this.selectedFornecedor.documento;
      }
    },

    selectedCondPago() {
      if (this.selectedCondPago) {
        this.despesa.condpago = this.selectedCondPago;
      }
    },

    selectedMoeda() {
      if (this.selectedMoeda && !this.utilizarCambio) {
        this.despesa.moeda_valor_id = this.selectedMoeda.id_moeda;
        this.despesa.moeda_valor_origem_id = this.selectedMoeda.id_moeda;
      } else {
        this.despesa.moeda_valor_id = this.moeda_despesa.id_moeda;
        this.despesa.moeda_valor_origem_id = this.selectedMoeda.id_moeda;
      }
    },

    utilizarCambio: {
      handler() {
        if (this.utilizarCambio) {
          this.despesa.valor = null;
          this.despesa.valor_origem = null;
        }
      },
      immediate: false,
    },

    valorDespesaOrigem() {
      if (this.valorDespesaOrigem) {
        this.despesa.valor_origem = this.$ci.parse(
          this.valorDespesaOrigem,
          this.vCurrencyOptions2
        );

        this.despesa.valor = this.cambioResolve(
          this.moeda_despesa.sigla,
          this.$ci.parse(this.despesa.cambio, this.vCurrencyOptions),
          this.selectedMoeda.sigla,
          this.$ci.parse(this.valorDespesaOrigem, this.vCurrencyOptions2)
        );
      }
    },
    valorDespesa() {
      if (!this.utilizarCambio) {
        if (this.valorDespesa) {
          this.despesa.valor = this.$ci.parse(
            this.valorDespesa,
            this.vCurrencyOptions2
          );
          this.despesa.valor_origem = this.$ci.parse(
            this.valorDespesa,
            this.vCurrencyOptions2
          );
        }
      }
    },
    cambioDespesa() {
      if (this.cambioDespesa) {
        this.despesa.cambio = this.cambioDespesa;
        if (this.valorDespesaOrigem) {
          this.despesa.valor_origem = this.$ci.parse(
            this.valorDespesaOrigem,
            this.vCurrencyOptions2
          );

          this.despesa.valor = this.cambioResolve(
            this.moeda_despesa.sigla,
            this.$ci.parse(this.despesa.cambio, this.vCurrencyOptions),
            this.selectedMoeda.sigla,
            this.$ci.parse(this.valorDespesaOrigem, this.vCurrencyOptions2)
          );

          this.valorDespesa = this.despesa.valor;
        }
      }
    },
  },

  methods: {
    addTipoDespesa() {
      this.dialogComprasTiposDespesas = true;
    },
    goToFatura() {
      if (this.$refs.form.validate()) {
        this.tab = 1;
      }
    },
    verificaValorFatura(item) {
      if (item > 0) {
        this.fatura_liberada = true;
      } else {
        this.fatura_liberada = false;
      }
    },
    cambioResolve(moeda_origem, valor_origem, moeda_destino, valor_destino) {
      let cotacao = null;
      valor_origem = Number(valor_origem);
      valor_destino = Number(valor_destino);

      // GUARANI
      if (moeda_origem === "G$" && moeda_destino === "U$") {
        cotacao = valor_origem * valor_destino;
      }
      if (moeda_origem === "G$" && moeda_destino === "R$") {
        cotacao = valor_origem * valor_destino;
      }

      // REAL
      if (moeda_origem === "R$" && moeda_destino === "U$") {
        cotacao = valor_origem / valor_destino;
      }
      if (moeda_origem === "R$" && moeda_destino === "G$") {
        cotacao = valor_destino / valor_origem;
      }

      // DOLAR
      if (moeda_origem === "U$" && moeda_destino === "R$") {
        cotacao = valor_destino / valor_origem;
      }
      if (moeda_origem === "U$" && moeda_destino === "G$") {
        cotacao = valor_destino / valor_origem;
      }

      return cotacao
        ? Math.round((cotacao + Number.EPSILON) * 100) / 100
        : cotacao;
    },

    setValorCambio() {
      if (this.utilizarCambio) {
        this.valorDespesa = this.despesa.valor;
      }
    },

    getFornecedores() {
      this.loading = true;
      fetchFornecedoresList()
        .then((response) => {
          this.fornecedores = response;
          if (this.edit) {
            this.selectedFornecedor = this.fornecedores.find((item) => {
              return item.id === this.item.fornecedor_id;
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createDespesa() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let block = false;
        if (this.possui_fatura && !this.fatura_liberada) {
          block = true;
          this.$toast.error(this.$tc("global.preenchaFaturaCorretamente"));
        }
        if (this.files.length) {
          this.files.forEach((file) => {
            if (file.size >= 104857600) {
              this.$toast.error(
                "O tamanho maximo do arquivo permitido é 100MB"
              );
              block = true;
              return;
            } else if (
              file.type.substr(-5) != "/jpeg" &&
              file.type.substr(-4) != "/png" &&
              file.type.substr(-4) != "/txt" &&
              file.type.substr(-4) != "/xls" &&
              file.type.substr(-6) != ".sheet" &&
              file.type.substr(-4) != "/pdf" &&
              file.type.substr(-4) != "/doc" &&
              file.type.substr(-5) != ".text" &&
              file.type.substr(-9) != ".document"
            ) {
              this.$toast.error("Formato não permitido");
              block = true;
              return;
            }
          });
        }
        if (!block) {
          let despesa = {};
          despesa.compras_id = this.compra_id;
          despesa.ncontrole = this.despesa.ncontrole;
          despesa.compras_tipos_despesas_id =
            this.despesa.compras_tipos_despesas_id;
          despesa.fornecedor_id = this.despesa.fornecedor_id;
          despesa.condpago = this.despesa.condpago;
          despesa.moeda_valor_id = this.despesa.moeda_valor_id;
          despesa.moeda_valor_origem_id = this.despesa.moeda_valor_origem_id;
          despesa.valor = this.despesa.valor;
          despesa.valor_origem = this.despesa.valor_origem;
          despesa.possui_fatura = this.possui_fatura;
          if (this.utilizarCambio) {
            despesa.cambio =
              this.selectedMoeda.sigla === "G$"
                ? this.despesa.cambio
                : parseFloat(this.despesa.cambio.replace(/,/, "."));
          }
          despesa.data_vencimento = this.despesa.data_vencimento;
          despesa.obs = this.despesa.obs;

          const despesa_2 = new FormData();

          for (let key in despesa) {
            if (
              despesa[key] !== null &&
              despesa[key] !== undefined &&
              despesa[key] !== ""
            ) {
              despesa_2.append(key, despesa[key]);
            }
          }
          if (this.possui_fatura) {
            despesa.fatura = this.fatura;
            despesa_2.append("fatura", JSON.stringify(despesa.fatura));
          }

          if (this.files.length) {
            for (var i = 0; i < this.files.length; i++) {
              let files = this.files[i];
              despesa_2.append("files[" + i + "]", files);
            }
          }

          postDespesa(despesa_2)
            .then(() => {
              this.loading = false;
              this.$emit("update:dialogAddEditDespesas", false);
              this.$emit("fetch-despesas");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } else {
        this.tab = 0;
      }
    },
    updateDespesa() {
      if (
        JSON.stringify(this.despesa) === JSON.stringify(this.despesa_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;
        let despesa = {};
        despesa.compras_id = this.compra_id;
        despesa.ncontrole = this.despesa.ncontrole;
        despesa.compras_tipos_despesas_id =
          this.despesa.compras_tipos_despesas_id;
        despesa.fornecedor_id = this.despesa.fornecedor_id;
        despesa.condpago = this.despesa.condpago;
        despesa.moeda_valor_id = this.despesa.moeda_valor_id;
        despesa.moeda_valor_origem_id = this.despesa.moeda_valor_origem_id;
        despesa.valor = this.despesa.valor;
        despesa.valor_origem = this.despesa.valor_origem;
        despesa.possui_fatura = this.possui_fatura;
        despesa.cambio =
          this.selectedMoeda.sigla === "G$"
            ? this.despesa.cambio
            : parseFloat(this.despesa.cambio.replace(/,/, "."));
        despesa.data_vencimento = this.despesa.data_vencimento;
        despesa.obs = this.despesa.obs;
        if (this.possui_fatura) {
          despesa.fatura = this.fatura;
        }

        putDespesa(this.despesa.id, despesa)
          .then(() => {
            this.loading = false;
            this.$emit("update:dialogAddEditDespesas", false);
            this.$emit("fetch-despesas");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    getDespesasTipos() {
      this.loading = true;

      fetchAllComprasTiposDespesas("?status=1")
        .then((response) => {
          this.despesas_tipos = response;
          if (this.edit) {
            this.selectedTipoDespesa = this.despesas_tipos.find((item) => {
              return item.id === this.item.compras_tipos_despesas_id;
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async getUltimaDespesa() {
      await fetchAllComprasTiposDespesas("?status=1")
        .then((response) => {
          this.despesas_tipos = response;
        })
        .catch((error) => {});
      this.selectedTipoDespesa = this.despesas_tipos.at(-1);
    },
    remove(item) {
      this.files.splice(
        this.files.findIndex((file) => file.name === item),
        1
      );
    },
    async getFaturaGasto() {
      this.loading = true;

      await fetchFaturaGasto(this.despesa.id + "?tipo_caixa=COMPRAS_DESPESAS")
        .then((response) => {
          this.fatura_edit = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    this.getFornecedores();
    this.getDespesasTipos();

    if (!this.edit) {
      this.selectedMoeda = this.moedas.find((item) => {
        return item.id_moeda === this.moeda_despesa.id_moeda;
      });
      // this.moeda_despesa = this.moedas.find((item) => {
      //   return item.id_moeda === this.moeda_empresa;
      // });
    }
    if (this.edit) {
      this.despesa = { ...this.item };
      this.despesa_original = { ...this.item };
      this.possui_fatura = this.despesa.possui_fatura;
      await this.getFaturaGasto();
      this.selectedMoeda = this.moedas.find((item) => {
        return item.id_moeda === this.item.moeda_valor_origem_id;
      });
      this.selectedCondPago = this.despesa.condpago;
      // this.moeda_despesa = this.moedas.find((item) => {
      //   return item.id_moeda === this.item.moeda_valor_id;
      // });

      this.cambioDespesa = this.despesa.cambio;
      if (this.utilizarCambio) {
        this.$ci.setValue(
          this.$refs.valorDespesaOrigem,
          Number(this.item.valor_origem)
        );
      } else {
        this.$ci.setValue(this.$refs.valorDespesa, Number(this.item.valor));
      }
    }
  },
};
</script>

<style scoped>
.theme--dark.v-btn--outlined {
  border-color: rgba(255, 255, 255, 0.3) !important;
}
.custom_tamanho_btn {
  height: 40px !important;
}
</style>
